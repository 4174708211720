import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Developer using this domain for personal projects`}</p>
    </blockquote>
    <p>{`Please note this site has no affiliation with the films or books about Bourne Identity or any spin off. I just so happen to have a surname of Bourne.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      